export const setCredentialsLocalStorage = async (credentials) => {
  const LCInternet = {};
  LCInternet.token = credentials.token;
  LCInternet.user = credentials.user;

  localStorage.setItem("LCInternet", JSON.stringify(LCInternet));
};

export const getToken = () => {
    return JSON.parse(localStorage.LCInternet).token;
}